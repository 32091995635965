import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';
import H from 'components/H/H';
import P from 'components/P/P';
import Span from 'components/Span/Span';

const lineColor = '#D4D4D4';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${({ index, theme }) =>
    index === 1 &&
    css`
      margin: 165px 0 205px;
      ${theme.tablet`margin: 76px 0 138px;`};
    `};

  ${({ theme }) => theme.tablet`
    flex-direction: column;
  `};

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    ${({ theme }) => theme.tablet`display: none`};
  }

  &::before {
    background-color: ${lineColor};
    width: 1px;
    height: ${({ index }) => (index === 1 && '600px') || '300px'};
    top: ${({ index }) => (index === 0 && '44%') || (index === 2 && '-55%')};
  }

  &::after {
    background: ${({ theme }) => theme.colors.white};
    width: 6px;
    height: 6px;
    border-radius: 100%;
    border: 1px solid ${lineColor};
    top: ${({ index }) =>
      (index === 0 && '44%') || (index === 1 && '50%') || '40%'};
  }

  ${({ theme, isSmall }) =>
    isSmall &&
    css`
      margin: 60px 0 0;

      ${theme.tablet`margin: 60px 0 0;`};

      &:nth-child(2) {
        ${theme.tablet`margin: 45px 0 0px;`};
      }

      &:nth-child(3) {
        ${theme.tablet`margin: 120px 0 0;`};
      }
    `};
`;

const StyledChildWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding-right: 108px;
  ${({ theme }) => theme.desktopSmall`padding-right: 50px;`};
  ${({ theme }) => theme.tablet`
    padding-right: 0;
    width: 100%;
    justify-content: center;
  `};

  ${({ theme, isSmall }) =>
    isSmall &&
    css`
      padding-right: 30px;

      ${theme.desktopSmall`padding-right: 30px;`};
      ${theme.tablet`padding-right: 0;`};
    `};
`;

const StyledTextContainer = styled.div`
  max-width: 445px;
  width: 50%;
  margin-left: 50px;
  ${({ theme }) => theme.tablet`
    text-align: center;
    margin: 45px 0 0 0;
  `};
  ${({ theme }) => theme.phone`
    width: 100%;
    padding: 0 42px;
  `};
  ${({ theme }) => theme.phoneSmall`
    padding: 0 28px;
  `};
`;

const StyledSvg = styled(SVG)`
  position: absolute;
  z-index: -1;
  bottom: 374px;
  right: 254px;
  ${({ theme }) => theme.tablet`display: none;`};
`;

const SingleStep = ({
  children,
  subText,
  heading,
  text,
  index,
  isCloud,
  isSmall,
}) => (
  <StyledWrapper index={index} isSmall={isSmall}>
    {isCloud && <StyledSvg src="/static/img/stepsImgs/cloud.svg" />}
    <StyledChildWrapper isSmall={isSmall}>{children}</StyledChildWrapper>
    <StyledTextContainer>
      <Span>{subText}</Span>
      <H as={isSmall ? 'h4' : 'h3'} margin="20px 0">
        {heading}
      </H>
      <P isSmall color="dark">
        {text}
      </P>
    </StyledTextContainer>
  </StyledWrapper>
);

SingleStep.propTypes = {
  children: PropTypes.element.isRequired,
  subText: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isCloud: PropTypes.bool,
  isSmall: PropTypes.bool,
};

SingleStep.defaultProps = {
  isCloud: false,
  isSmall: false,
};

export default SingleStep;
