import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  color: ${({ theme, color }) =>
    color === 'white' ? theme.colors.white : theme.colors.dark};
  font-size: 12px;
  line-height: 14px;
  font-family: 'Bold';
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '2px'};
  ${({ margin }) => margin && `margin: ${margin};`};
`;

const Span = props => <StyledSpan {...props} />;

export default Span;
