import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import styled, { css } from 'styled-components';

import Navigation from 'elements/Navigation/Navigation';
import Footer from 'elements/Footer/Footer';
import { useAppContext } from '../../store';

const googleMapsAPIKey = 'AIzaSyCvX_Erv3uDmzUHOUMl77Hn8rS1HihXX18';
const googleMapsAPIUrl = `https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places`;

const Wrapper = styled.main`
  position: relative;
  ${({ isPaddingTop }) => isPaddingTop && 'padding-top: 100px;'};
  ${({ isDark }) =>
    isDark &&
    css`
      background: ${({ theme }) => theme.colors.dark};
    `};
  ${props =>
    !props.withoutPositionRelative &&
    css`
      overflow: hidden;
    `};
`;

const Layout = ({
  children,
  title,
  withoutPositionRelative,
  withoutNavbar,
  withoutFooter,
  isWhiteNav,
  isPaddingTop,
  isDark,
  isBlack,
  isEo,
}) => {
  const { state, dispatch, intl } = useAppContext();

  const navigationFooterItems = [
    {
      link: 'about',
      label: intl.get('app.menu.one'),
      tag: 'Click on About in footer',
    },
    {
      link: 'renters',
      label: intl.get('app.menu.four.footer'),
      tag: 'Click on Renters in footer',
    },
    {
      link: 'faq',
      label: intl.get('app.menu.two'),
      tag: 'Click on FAQ in footer',
    },
    {
      link: 'marketing',
      label: intl.get('app.menu.five.footer'),
      tag: 'Click on Marketing in footer',
    },
    {
      link: 'contact',
      label: intl.get('app.menu.three'),
      tag: 'Click on Contact in footer',
    },
    {
      link: 'legal',
      label: intl.get('app.menu.six.footer'),
      tag: 'Click on Legal in footer',
    },
  ];
  const navigationItems = [
    {
      link: 'about',
      label: intl.get('app.menu.one'),
      ga: 'Click on About in menu',
    },
    {
      link: 'faq',
      label: intl.get('app.menu.two'),
      ga: 'Click on FAQ in Menu',
    },
    {
      link: 'contact',
      label: intl.get('app.menu.three'),
      ga: 'Click on Contact in Menu',
    },
    { link: 'legal', label: intl.get('app.menu.six.footer') },
  ];
  return (
    <>
      <Head>
        <title>
          Keller Covered
          {title ? ` | ${title}` : ''}
        </title>
        <script type="text/javascript" src={googleMapsAPIUrl} />
      </Head>
      {!withoutNavbar && (
        <Navigation
          items={navigationItems}
          isWhiteNav={isWhiteNav}
          dispatch={dispatch}
          state={state}
          isEo={isEo}
        />
      )}
      <Wrapper
        isPaddingTop={isPaddingTop}
        withoutPositionRelative={withoutPositionRelative}
        isDark={isDark}
      >
        {children}
      </Wrapper>
      {!withoutFooter && (
        <Footer isBlack={isBlack} linkItems={navigationFooterItems} />
      )}
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withoutPositionRelative: PropTypes.bool,
  title: PropTypes.string,
  withoutNavbar: PropTypes.bool,
  withoutFooter: PropTypes.bool,
  isWhiteNav: PropTypes.bool,
  isPaddingTop: PropTypes.bool,
  isDark: PropTypes.bool,
  isBlack: PropTypes.bool,
};

Layout.defaultProps = {
  withoutPositionRelative: false,
  title: null,
  withoutNavbar: false,
  withoutFooter: false,
  isWhiteNav: false,
  isPaddingTop: false,
  isDark: false,
  isBlack: false,
};

export default Layout;
