import styled from 'styled-components';

const Div = styled.div`
  /* GENERAL */
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}

  /* FLEX */
  ${({ display }) => display && `display: ${display};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ theme, mobileWidth }) => theme.phone`
      width: ${mobileWidth};
  `};
  ${({ theme }) => theme.phone`
      text-align: center;
  `};
`;

export default Div;
