import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../components/Button/ButtonPrimary';
import { useAppContext } from '../../store';
import handleRedirection from '../../shared/hooks';
import Div from '../../components/Div/Div';
import { BASE_EO_LINK, BASE_LINK } from '../../shared/config/config';

const MainButtonsContainer = ({ gaCategory, centerButtons }) => {
  const { intl } = useAppContext();
  const redirectToApp = handleRedirection();
  const mainButtonGAClick = baseUrl => {
    ReactGA.event({
      category: gaCategory,
      action: 'Click on top Get Multiple quotes button',
    });
    redirectToApp(baseUrl);
  };

  const mainButtons = [
    { cta: intl.get('app.button.home.insurance.quick.quote') },
    {
      cta: intl.get('app.button.renters.insurance'),
      baseUrl: `${BASE_LINK}/renters`,
    },
    {
      cta: intl.get('app.button.bundle.quotes.home.auto.more'),
      baseUrl: 'https://bit.ly/3Rvivvv',
    },
    {
      cta: intl.get('app.button.personal.cyber.protection'),
      baseUrl:
        'https://www.mycyberprotection.com/kellercovered/en/homepage.html',
    },
    {
      cta: intl.get('app.button.home.warranty'),
      baseUrl: 'https://www.xcover.com/kellercoveredhomewarranty',
    },
    {
      cta: intl.get('app.button.eo.for.kw.mcs'),
      baseUrl: BASE_EO_LINK,
    },
  ];

  return (
    <Div
      paddingTop="30px"
      margin={centerButtons ? '0 auto' : '0'}
      width="70%"
      mobileWidth="100%"
    >
      {mainButtons.map((o, i) => (
        <ButtonPrimary
          key={`mainButton${i + 1}`}
          padding="20px"
          margin="0 40px 15px 0"
          mobileMargin="10px auto"
          width="275px"
          mobileWidth="275px"
          onClick={() => mainButtonGAClick(o.baseUrl)}
          data-test-id="getButton"
        >
          {o.cta}
        </ButtonPrimary>
      ))}
    </Div>
  );
};

MainButtonsContainer.propTypes = {
  gaCategory: PropTypes.string,
  centerButtons: PropTypes.bool,
};

MainButtonsContainer.defaultProps = {
  gaCategory: 'MainButtons',
  centerButtons: false,
};

export default MainButtonsContainer;
