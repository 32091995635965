import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Lottie from 'react-lottie';
import SVG from 'react-inlinesvg';
import { IeStyles } from 'styles/utils';

const ImageWrapper = styled.div`
  position: absolute;
  background: transparent no-repeat;
  ${props =>
    !!props.static &&
    css`
      position: relative;
    `};
  ${props =>
    !!props.customSize &&
    css`
      div svg {
        height: ${props.customSize[0]}px !important;
        width: ${props.customSize[1]}px !important;
      }
    `};
  ${props =>
    !!props.animationPosition &&
    css`
      div svg {
        position: absolute;
        left: ${props.animationPosition[0]}%;
        top: ${props.animationPosition[1]}%;
        transform: translate(
          ${-props.animationPosition[0]}%,
          ${-props.animationPosition[1]}%
        );
      }
    `};
  ${props =>
    !!props.rotated &&
    css`
      div svg {
        transform: rotateY(180deg) !important;
      }
    `};
  ${props =>
    !!props.position &&
    css`
      left: ${props.position[0]}%;
      top: ${props.position[1]}%;
      transform: translate(${-props.position[0]}%, ${-props.position[1]}%);
    `};
  ${props =>
    !!props.animationData &&
    css`
      width: 500px;
    `};
  @media (max-width: 1319px) {
    ${props =>
      !props.neverStatic &&
      css`
        position: static;
        transform: none;
      `};
    ${props =>
      props.hiddenOnMobile &&
      css`
        display: none;
      `};
    ${props =>
      !!props.mobilePosition &&
      css`
        transform: translate(
          ${props.mobilePosition[0]}px,
          ${props.mobilePosition[1]}px
        );
      `};
    ${props =>
      !!props.mobilePosition &&
      !!props.neverStatic &&
      css`
        left: ${props.mobilePosition[0]}%;
        top: ${props.mobilePosition[1]}%;
        transform: translate(
          -${Math.abs(props.mobilePosition[0])}%,
          -${Math.abs(props.mobilePosition[1])}%
        );
      `};
  }
  @media (max-width: 900px) {
    ${props =>
      !!props.animationData &&
      css`
        z-index: 100;
        width: 250px;
      `};
  }

  ${props =>
    props.position &&
    props.position[1] < 0 &&
    !props.rotated &&
    IeStyles`
      top: -55%;
    `};
`;

const StyledGif = styled.img`
  @media (max-width: 900px) {
    ${props =>
      !!props.animationGif &&
      css`
        width: 250px;
      `};
  }
`;

const bodymovinOptions = animation => ({
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: animation.default,
  rendererSettings: {
    'shape-rendering': 'optimizeSpeed',
  },
});

const Image = ({ src, animationData, animationGif, alt, imgSrc, ...rest }) => (
  <ImageWrapper animationData={animationData} {...rest}>
    {src && <SVG src={src} />}
    {animationGif && (
      <StyledGif animationGif={animationGif} src={animationGif} alt={alt} />
    )}
    {imgSrc && <img src={imgSrc} alt={alt} />}
    {animationData && <Lottie options={bodymovinOptions(animationData)} />}
  </ImageWrapper>
);

Image.propTypes = {
  src: PropTypes.string,
  position: PropTypes.arrayOf(PropTypes.number),
  mobilePosition: PropTypes.arrayOf(PropTypes.number),
  hiddenOnMobile: PropTypes.bool,
  neverStatic: PropTypes.bool,
  animationPosition: PropTypes.arrayOf(PropTypes.number),
  rotated: PropTypes.bool,
  animationData: PropTypes.shape({}),
  animationGif: PropTypes.string,
  alt: PropTypes.string,
  imgSrc: PropTypes.string,
};

Image.defaultProps = {
  src: null,
  mobilePosition: null,
  hiddenOnMobile: false,
  neverStatic: false,
  position: null,
  animationPosition: null,
  rotated: null,
  animationData: null,
  animationGif: null,
  alt: null,
  imgSrc: null,
};

export default Image;
