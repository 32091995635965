import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DogIcon from 'components/DogIcon/DogIcon';
import Span from 'components/Span/Span';
import CheckIcon from 'components/CheckIcon/CheckIcon';

const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 32px;
  max-width: 340px;
  width: 100%;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  ${({ theme }) => theme.phone`max-width: 272px;`};
`;

const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSvgContainer = styled.div`
  text-align: center;
  margin: 34px 0 14px;
  position: relative;
`;

const StyledText = styled.p`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledImg = styled.img`
  position: relative;
`;
const FirstStep = ({ isRentersPage }) => (
  <StyledContainer>
    <StyledTextContainer>
      <DogIcon size="40px" mobileSize="34px" />
      <Span margin="0 0 0 15px">
        {isRentersPage ? 'STEP 1 OF 5' : 'STEP 1 OF 7'}
      </Span>
    </StyledTextContainer>
    <StyledSvgContainer>
      <CheckIcon
        top="12px"
        right="25px"
        mobileTop="15px"
        mobileRight="4px"
        zIndex="1"
      />
      <StyledImg src="/static/img/stepsImgs/house.svg" />
    </StyledSvgContainer>
    <StyledText>Single Family</StyledText>
  </StyledContainer>
);

FirstStep.propTypes = {
  isRentersPage: PropTypes.bool,
};

FirstStep.defaultProps = {
  isRentersPage: false,
};

export default FirstStep;
