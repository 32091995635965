import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import SingleStep from './SingleStep';
import FirstStep from './FirstStep';
import PartnerTile from './PartnerTile';
import ThirdStep from './ThirdStep';
import { useAppContext } from '../../../store';

const StyledSection = styled.section`
  margin-top: 96px;
  position: relative;
  ${({ theme }) => theme.tablet`margin-top: 76px`};

  ${({ theme, isSmall }) =>
    isSmall &&
    css`
      margin-top: 60px;

      ${theme.tablet`margin-top: 60px`};
    `};
`;

const StyledWrapper = styled.div`
  max-width: 340px;
  width: 100%;
  ${({ theme }) => theme.phone`max-width: 272px`};
`;

const StyledSvg = styled(SVG)`
  position: absolute;
  z-index: -1;
  top: ${({ top }) => top};
  ${({ isDesktop, theme }) => isDesktop && theme.tablet`display: none;`};
  ${({ isMobile }) =>
    isMobile &&
    css`
      @media (min-width: 690px) {
        display: none;
      }
    `};
`;

const SecondStep = ({ isSmall }) => {
  const partners = [
    { name: 'Lemonade', price: '$843' },
    { name: 'Travelers', price: '$1,500' },
    { name: 'MetLife', price: '$1,500' },
    { name: 'Nationwide', price: '$1,500' },
  ];
  return (
    <StyledWrapper>
      {partners.map((el, index) => (
        <PartnerTile
          isCheckIcon={index % 2 === 1}
          key={el.name}
          {...el}
          isSmall={isSmall}
        />
      ))}
    </StyledWrapper>
  );
};

const StepsSection = ({ isSmall, withoutShape, isRentersPage }) => {
  const { intl } = useAppContext();
  const steps = [
    {
      heading: isRentersPage
        ? intl.get('app.home.step.one.renters.head')
        : intl.get('app.home.list.step.one.subtitle'),
      text: isRentersPage
        ? intl.get('app.home.step.one.renters.title')
        : intl.get('app.home.list.step.one.description'),
      childComponent: <FirstStep isRentersPage />,
      subText: intl.get('app.home.list.step.one.title'),
    },
    {
      heading: intl.get('app.home.list.step.two.subtitle'),
      text: intl.get('app.home.list.step.two.description'),
      childComponent: <SecondStep isSmall={isSmall} />,
      subText: intl.get('app.home.list.step.two.title'),
    },
    {
      heading: intl.get('app.home.list.step.three.subtitle'),
      text: isRentersPage
        ? intl.get('app.home.step.three.renters.title')
        : intl.get('app.home.list.step.three.description'),
      childComponent: <ThirdStep isSmall={isSmall} />,
      subText: intl.get('app.home.list.step.three.title'),
    },
  ];

  return (
    <StyledSection isSmall={isSmall}>
      {!withoutShape && (
        <>
          <StyledSvg
            top="212px"
            isDesktop
            src="/static/img/stepsImgs/backgroundShape.svg"
          />
          <StyledSvg
            top="550px"
            isMobile
            src="/static/img/stepsImgs/mobileBackgroundShape.svg"
          />
        </>
      )}

      {steps.map((el, index) => (
        <SingleStep
          isCloud={index + 1 === steps.length}
          key={el.heading}
          index={index}
          isSmall={isSmall}
          {...el}
        >
          {el.childComponent}
        </SingleStep>
      ))}
    </StyledSection>
  );
};

StepsSection.propTypes = {
  isSmall: PropTypes.bool,
  withoutShape: PropTypes.bool,
  isRentersPage: PropTypes.bool,
};

StepsSection.defaultProps = {
  isSmall: false,
  withoutShape: false,
  isRentersPage: false,
};

SecondStep.propTypes = {
  isSmall: PropTypes.bool,
};

SecondStep.defaultProps = {
  isSmall: false,
};

export default StepsSection;
