import { BASE_APP_LINK } from 'shared/config/config';
import { useRouter } from 'next/router';
import { useAppContext } from '../../store';
import {
  ORGANIC_SOURCE_IDENTIFIER,
  RENTERS_SOURCE_IDENTIFIER,
} from '../config/config';

const handleRedirection = () => {
  const { state } = useAppContext();
  const { pathname } = useRouter();

  const redirectToApp = baseUrl => {
    const params = new URLSearchParams(window.location.search);
    const campaign = params.get('campaign_id');
    const agentReferral = JSON.parse(localStorage.getItem('eventPage')) || {};
    const agentId = params.get('kwuid');
    const isPivot = campaign === 'PIVOT' && !!agentId;
    const isRenters = pathname === '/renters';
    const isAgentReferral = agentReferral.campaign === 'AGENTREFERRAL';
    const link = document.createElement('a');
    let redirectionHref;
    if (isPivot) {
      redirectionHref =
        baseUrl || `${BASE_APP_LINK}/?campaign_id=PIVOT&kwuid=${agentId}`;
    } else if (isRenters) {
      redirectionHref = `${BASE_APP_LINK}/?source_identifier=${RENTERS_SOURCE_IDENTIFIER}&language=${
        state.language
      }`;
    } else if (isAgentReferral) {
      const { source_identifier, agentId } = JSON.parse(
        localStorage.getItem('eventPage')
      );
      redirectionHref =
        baseUrl ||
        `${BASE_APP_LINK}/?source_identifier=${source_identifier}&agent_id=${agentId}&language=${
          state.language
        }`;
    } else {
      redirectionHref =
        baseUrl ||
        `${BASE_APP_LINK}/?source_identifier=${ORGANIC_SOURCE_IDENTIFIER}&language=${
          state.language
        }`;
    }

    link.href = redirectionHref;

    document.body.appendChild(link);
    link.click();
  };

  return redirectToApp;
};

export default handleRedirection;
