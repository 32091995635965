import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';
import Image from 'components/Image/Image';
import H from 'components/H/H';
import Div from 'components/Div/Div';
import { useAppContext } from '../../store';

const partnersImagesTop = [
  { src: '/static/img/partnersImgs/lemonade.svg' },
  { imgSrc: '/static/img/partnersImgs/hippo.png' },
  { src: '/static/img/partnersImgs/metlife.svg' },
  { src: '/static/img/partnersImgs/nationwide.svg' },
];

const partnersImagesBottom = [
  { src: '/static/img/partnersImgs/kemper.svg' },
  { src: '/static/img/partnersImgs/safeco.svg' },
  { src: '/static/img/partnersImgs/travelers.svg' },
  { src: '/static/img/partnersImgs/encompass.svg' },
];

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: -284px;
  ${({ theme }) => theme.desktopSmall`margin-top: -146px;`};
  ${({ theme }) => theme.tablet`margin-top: -46px;`};
  ${({ theme }) => theme.phone`margin-top: -32px;`};
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `};
`;

const StyledWrapper = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  text-align: center;
`;

const IconsContainer = styled(Div)`
  margin-bottom: 85px;
  ${({ theme }) => theme.desktopSmall`margin-bottom: 0`};
`;

const StyledContentContainer = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  padding: 86px 80px 72px;

  ${({ theme }) => theme.phone`padding 38px 4px 40px;`};
`;

const StyledImage = styled(Image)`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 50px;
  }

  ${({ theme }) => theme.desktopSmall`
    width: calc(50% - 40px);
    margin: 22px 10px;
  `};
  ${({ theme }) => theme.phone`
    img,
    svg {
      width: 100%;
    }
    img {
      height: auto;
    }
  `};
`;

const StyledHeadingWrapper = styled.div`
  margin-bottom: 64px;
  ${({ theme }) => theme.phone`
    margin-bottom: 5px;
    padding: 0 12px;
  `};
`;

const StyledSvg = styled(SVG)`
  margin-left: 78px;
  transform: translateY(4px);
  ${({ theme }) => theme.phone`
    margin-left: -8px;
    width: 540px;
    height: 160px;
    transform: translateY(3px);
  `};
`;

const StyledSvg2 = styled(SVG)`
  ${({ id, theme }) =>
    id === 'clouds' &&
    css`
      transform: translateY(76px);
      ${theme.phone`display: none;`};
    `};

  ${({ id, theme }) =>
    id === 'house' &&
    css`
      margin: 0 auto;
      transform: translateY(4px);
      ${theme.phone`
        width: 260px;
        height: 130px;
        transform: translateY(5px);
      `};
    `};
`;

const StyledMobileSvg = styled(SVG)`
  display: none;
  ${({ theme }) => theme.phone`
    display: block;
    position: absolute;
    left: 0;
    top: -55px;
  `};
`;

const PartnersSection = ({ noMargin, classicHouse, title }) => {
  const { intl } = useAppContext();
  return (
    <StyledSection noMargin={noMargin}>
      <StyledWrapper>
        {classicHouse ? (
          <>
            <StyledSvg2 id="clouds" src="/static/img/callActionClouds.svg" />
            <StyledSvg2 id="house" src="/static/img/callActionHouse.svg" />
          </>
        ) : (
          <StyledSvg src="/static/img/leftHouse.svg" />
        )}

        <StyledContentContainer>
          {classicHouse && (
            <StyledMobileSvg src="/static/img/callActionMobileClouds.svg" />
          )}
          <StyledHeadingWrapper>
            <H color="white" as="h3">
              {intl.get('app.home.banner.industry.list')}
            </H>
          </StyledHeadingWrapper>
          <IconsContainer
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
          >
            {partnersImagesTop.map(props => (
              <StyledImage
                static
                {...props}
                key={props.imgSrc ? props.imgSrc : props.src}
              />
            ))}
          </IconsContainer>
          <Div display="flex" justifyContent="space-evenly" flexWrap="wrap">
            {partnersImagesBottom.map(props => (
              <StyledImage
                static
                {...props}
                key={props.imgSrc ? props.imgSrc : props.src}
              />
            ))}
          </Div>
          <H as="h3" color="white" margin="82px 0 0 0" mobileMargin="0">
            {intl.get('app.home.banner.more.carriers')}
          </H>
        </StyledContentContainer>
      </StyledWrapper>
    </StyledSection>
  );
};

PartnersSection.propTypes = {
  imgSrc: PropTypes.string,
  src: PropTypes.string,
  noMargin: PropTypes.bool,
  classicHouse: PropTypes.bool,
  title: PropTypes.string,
};

PartnersSection.defaultProps = {
  imgSrc: '',
  src: '',
  noMargin: false,
  classicHouse: false,
  title: 'Quotes from industry leading companies',
};

export default PartnersSection;
