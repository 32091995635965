import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';

const StyledSection = styled.section`
  position: relative;
  margin: ${({ sectionMargin }) => sectionMargin.desktop};
  text-align: center;
  ${({ theme, sectionMargin }) => theme.phone`margin: ${sectionMargin.mobile}`};
`;

const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;

  ${({ noOuterPadding }) =>
    noOuterPadding &&
    css`
      padding: 0;
    `};
`;

const StyledContentContainer = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: ${({ containerPadding }) => containerPadding.desktop};

  ${({ theme, containerPadding }) =>
    theme.phone`padding: ${containerPadding.mobile}`};

  ${({ theme, isGray }) =>
    isGray &&
    css`
      background-color: ${theme.colors.darkGray};
    `};
`;

const StyledSvg = styled(SVG)`
  ${({ id, smallImage, theme }) =>
    id === 'clouds' &&
    css`
      transform: translateY(76px);

      ${smallImage &&
        css`
          height: 100px;
        `};

      ${theme.phone`display: none;`};
    `};

  ${({ id, smallImage, theme }) =>
    id === 'house' &&
    css`
      transform: translateY(4px);

      ${smallImage &&
        css`
          height: 130px;
        `};

      ${theme.phone`
        width: 260px;
        height: 130px;
        transform: translateY(5px);
      `};
    `};
`;

const StyledMobileSvg = styled(SVG)`
  display: none;
  ${({ theme }) => theme.phone`
    display: block;
    position: absolute;
    left: 0;
    top: -72px;
  `};
`;

const CallActionSection = ({
  children,
  sectionMargin,
  containerPadding,
  isGray,
  noOuterPadding,
  smallImage,
}) => (
  <StyledSection sectionMargin={sectionMargin}>
    <StyledMobileSvg
      src="/static/img/callActionMobileClouds.svg"
      smallImage={smallImage}
    />
    <StyledContainer noOuterPadding={noOuterPadding}>
      <StyledSvg
        id="clouds"
        src="/static/img/callActionClouds.svg"
        smallImage={smallImage}
      />
      <StyledSvg
        id="house"
        src="/static/img/callActionHouse.svg"
        smallImage={smallImage}
      />
      <StyledContentContainer
        containerPadding={containerPadding}
        isGray={isGray}
      >
        {children}
      </StyledContentContainer>
    </StyledContainer>
  </StyledSection>
);

CallActionSection.propTypes = {
  children: PropTypes.element.isRequired,
  sectionMargin: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
  containerPadding: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
  isGray: PropTypes.bool,
  noOuterPadding: PropTypes.bool,
  smallImage: PropTypes.bool,
};

CallActionSection.defaultProps = {
  isGray: false,
  noOuterPadding: false,
  smallImage: false,
};

export default CallActionSection;
