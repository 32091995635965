import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ top }) => top && `top: ${top};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};
  transform: translate(-50%, -50%);
  ${({ theme, mobileTop, mobileRight }) => theme.phone`
    height: 36px;
    width: 36px;
    top: ${mobileTop};
    right: ${mobileRight};
  `};
  i {
    color: white;
  }
`;

const CheckIcon = ({ top, right, mobileTop, mobileRight, zIndex }) => (
  <StyledContainer
    top={top}
    right={right}
    mobileTop={mobileTop}
    mobileRight={mobileRight}
    zIndex={zIndex}
  >
    <i className="far fa-lg fa-check" />
  </StyledContainer>
);

CheckIcon.propTypes = {
  top: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
  mobileRight: PropTypes.string.isRequired,
  mobileTop: PropTypes.string,
  zIndex: PropTypes.string,
};

CheckIcon.defaultProps = {
  mobileTop: '',
  zIndex: '',
};

export default CheckIcon;
