import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckIcon from 'components/CheckIcon/CheckIcon';

const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  ${({ theme, isShadow }) => isShadow && `box-shadow: ${theme.boxShadow}`};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding: 28px 60px 28px 36px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.phone`
    padding: 22px 48px 22px 28px;
  `};
`;

const StyledImg = styled.img`
  width: 34px;
  height: 34px;
  ${({ theme }) => theme.phone`
    width: 26px;
    height: 26px;
  `};
`;

const StyledName = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #343342;
  margin: 0 12px;

  ${({ theme }) => theme.phone`
    margin-left: 8px;
    font-size: 13px;
    line-height: 19px;
  `};
`;

const StyledPrice = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 21px;
  line-height: 22px;
  margin-left: auto;
  span {
    font-size: 11px;
    line-height: 9px;
  }

  ${({ theme }) => theme.phone`
    font-size: 17px;
    line-height: 18px;
  `};
`;

const PartnerTile = ({ name, price, isShadow, isCheckIcon }) => (
  <StyledContainer isShadow={isShadow}>
    {isCheckIcon && <CheckIcon top="50%" right="-42px" mobileRight="-29px" />}
    <StyledImg src={`/static/img/stepsImgs/${name}.png`} />
    <StyledName>{name}</StyledName>
    <StyledPrice>
      {price}
      <span>/year</span>
    </StyledPrice>
  </StyledContainer>
);

PartnerTile.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  isShadow: PropTypes.bool,
  isCheckIcon: PropTypes.bool,
};

PartnerTile.defaultProps = {
  isShadow: true,
  isCheckIcon: false,
};

export default PartnerTile;
