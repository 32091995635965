import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import DogIcon from 'components/DogIcon/DogIcon';
import Span from 'components/Span/Span';
import PartnerTile from './PartnerTile';

const StyledWrapper = styled.div`
  max-width: 340px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow};
  position: relative;
  border-radius: 0 0 6px 6px;
  background: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.phone`max-width: 272px;`};
`;

const StyledTextContainer = styled.div`
  background-color: #1f1d3c;
  border-radius: 6px 6px 0 0;
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSvg = styled(SVG)`
  position: absolute;
  top: -72px;
  left: -60px;
  ${({ theme }) => theme.phone`
    width: 381px;
    top: -80px;
    left: -46px;
  `};

  ${({ theme, isSmall }) =>
    isSmall &&
    css`
      width: 350px;
      left: 50%;
      transform: translateX(-50%);

      ${theme.tablet`
        width: unset;
        left: -60px;
        transform: unset;
      `};
    `};
`;

const StyledImg = styled.img`
  width: 100%;
  padding: 0 40px 28px 40px;
`;

const ThirdStep = ({ isSmall }) => (
  <StyledWrapper>
    <StyledSvg src="/static/img/stepsImgs/confetti.svg" isSmall={isSmall} />
    <StyledTextContainer>
      <DogIcon size="40px" mobileSize="34px" />
      <Span margin="10px 0 0 0" color="white" letterSpacing="1px">
        CONGRATS, YOU&apos;RE COVERED!
      </Span>
    </StyledTextContainer>
    <PartnerTile isShadow={false} name="Lemonade" price="$843" />
    <StyledImg alt="signature" src="/static/img/stepsImgs/signature.png" />
  </StyledWrapper>
);

ThirdStep.propTypes = {
  isSmall: PropTypes.bool,
};

ThirdStep.defaultProps = {
  isSmall: false,
};

export default ThirdStep;
